body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  background-color: #030303 !important;
  font-family: 'Strait', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: 'Strait', sans-serif;
}

input{
  font-family: 'Strait', sans-serif;
}

#root{
  height: 100%;
  min-height: 100vh;
}
html, body, #root, .App {
  height: 100%;
}

/*
::-webkit-scrollbar {
  width: 8px;
  height: 9px;
  border-radius: 24px;
}

!* Track *!
::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 6px;
}

!* Handle *!
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 6px;
}

!* Handle on hover *!
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
  border-radius: 6px;
}*/
